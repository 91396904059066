import React from "react";

import { cn } from "~/utilities/cn";

interface IconCloseProps extends React.SVGProps<SVGSVGElement> {
  className?: string; // Optional className to pass Tailwind CSS styles
}

const IconClose = React.forwardRef<SVGSVGElement, IconCloseProps>(
  ({ className, onClick, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        width={props.width || "24"}
        height={props.height || "24"}
        onClick={onClick}
        className={cn(
          "h-6 w-6 cursor-pointer text-color", // Default size and color
          className // Applying any additional className passed via props
        )}
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props} // Spread the remaining props (like aria-hidden, etc.)
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6 18L17.94 6M18 18L6.06 6"
        />
      </svg>
    );
  }
);

IconClose.displayName = "IconClose"; // For debugging purposes

export default IconClose;
